/* html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
} */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'avenue-mono';
    src: url('./fonts/avenue-mono-web/Avenue Mono.woff') format('woff');
  }
}

.react-datepicker__input-container input {
  min-width: 210px;
}

/* Fix for Next.js Image component */
.nextImgContainer {
  width: 100%;
}

.nextImgContainer > div {
  position: unset !important;
}

.nextImg {
  object-fit: contain;
  width: 100% !important;
  position: relative !important;
  height: unset !important;
}

.slick-prev:before,
.slick-next:before {
  color: #222 !important;
}

.react-dropdown-tree-select,
.dropdown,
.dropdown-trigger {
  width: 100%;
  height: fit-content;
  min-height: 30px;
}
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow {
  border: none !important;
  padding: 0 !important;
}

.tag-item,
.search {
  border: none !important;
  outline: none !important;
  background: none !important;
  height: 20px !important;
  padding-left: 3px !important;
  padding-top: 4px !important;
}

.tag-item:focus,
.search:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.react-dropdown-tree-select,
.dropdown,
.dropdown-trigger.arrow.bottom:after {
  content: none !important;
}
.react-dropdown-tree-select,
.dropdown,
.dropdown-trigger.arrow.top:after {
  content: none !important;
}

.react-dropdown-tree-select .dropdown .dropdown-content {
  width: 340px !important;
  max-width: 340px !important;
  margin-top: 3px !important;
  border-radius: 5px !important;
}

@media only screen and (max-width: 768px) {
  .react-dropdown-tree-select .dropdown .dropdown-content {
    width: 335px !important;
  }
}

.node,
.tree[role='treeitem'] {
  border: none !important;
  outline: none !important;
  background: none !important;
  height: 30px !important;
  font-size: 0.875rem !important;
}

.sigCanvas {
  @apply border border-gray-300 rounded bg-white;
}